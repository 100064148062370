<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> B2B 매출내역</h2>
            </div>
            <div class="con mt-30">
                <div class="con_table">
                    <div class="search2 mb-30">
                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <label>기간검색 :
                            <select name="birth-yyyy" v-model="startYY" class="ml-20" id="birth-yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                            <select name="birth-mm" v-model="startMM" class="ml-10" id="birth-mm">
                                <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                            </select>
                            ~
                            <select name="birth-yyyy" v-model="endYY" class="ml-10" id="birth-yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                            <select name="birth-mm" v-model="endMM" class="ml-10" id="birth-mm">
                                <option v-for="index in 12" :key="index" :value="index">{{index}}월</option>
                            </select>
                            <br>
                            <select v-model="idxCrmCompany" @change="getPurchaseHistory()" class="font-16 w-120px h-40px pl-10 mt-20">
                                <option value="-1">기업선택</option>
                                <option v-for="(item, index) of companyList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>
                            <select v-model="purPayWay" @change="getPurchaseHistory()" class="font-16 w-150px h-40px mt-20 pl-10">
                                <option value="">결제구분</option>
                                <option value="현금">현금</option>
                                <option value="카드">카드</option>
                                <option value="계좌이체">계좌이체</option>
                                <option value="상품권">상품권</option>
                            </select>
                            <a class="btn_search pointer" @click="getPurchaseHistory()">Search<span></span></a>
                        </label>
                    </div>

                    <table class="default_table">
                        <tbody v-if="loading">
                            <tr>
                                <th>환불</th>
                                <th>미수금</th>
                                <th>매출액</th>
                            </tr>
                            <tr>
                                <td colspan="3">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>환불</th>
                                <th>미수금</th>
                                <th>매출액</th>
                            </tr>
                            <tr>
                                <td>{{numberWithCommas(purchase.purRefund)}}</td>
                                <td>{{numberWithCommas(purchase.purReceivable)}}</td>
                                <td>{{numberWithCommas(purchase.purCash - purchase.purRefund - purchase.purReceivable)}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="default_table">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>날짜</th>
                                <th>기업</th>
                                <th>결제상태</th>
                                <th>결제금액</th>
                            </tr>
                            <tr>
                                <td colspan="5">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>날짜</th>
                                <th>기업</th>
                                <th>결제상태</th>
                                <th>결제금액</th>
                            </tr>
                            <tr v-for="(item, index) of purList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{item.purDate}}</td>
                                <td>{{item.companyName}}</td>
                                <td>{{item.status}}</td>
                                <td>{{numberWithCommas(item.purPrice)}}</td>
                            </tr>
                            <tr v-if="purList.length === 0">
                                <td colspan="5">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    components: {

    },
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜

        idxCrmCompany: -1,
        companyName: '',
        staffName: '',
        type: 'B2B',
        purPayWay: '',
        startYY: 2020,
        startMM: 1,
        endYY: 2020,
        endMM: 1,
        
        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)

        purList: [],
        purchase: '',
        companyList: [],
        loading:true,
    }),

    mounted() {

        let date = new Date()
        this.startYY = date.getFullYear()
        this.startMM = date.getMonth() + 1
        this.endYY = date.getFullYear()
        this.endMM = date.getMonth() + 1
        this.getCenterListAll()
        this.getMetaList()
        this.getProductList()
        this.getPurchaseHistory()
        this.getCompanyListAll()

    },
    methods: {

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined || isNaN(x) ) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        // 현재 년도에 따라서 120년만큼 selectbox
        parseYear() {
            var nowYear = new Date().getFullYear()
            var parseYear = []
            for (var i = 0; i <= 120; i++) {
                parseYear.push(nowYear - i)
            }
            return parseYear
        },

        // 소속리스트(기관리스트)
        getCompanyListAll() {
            this.axios.get('/api/v1/company/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.companyList = res.data.companyList
                    } else {
                        //alert('소속리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.typeList = res.data.typeList
                    }
                })
        },

        getProductList() {

            var params = {
                value: this.gubun
            }
            this.axios.get('/api/v1/client/gubun', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.gubunList = res.data.gubunList
                        this.productList = res.data.productList
                    }
                })
        },


        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getPurchaseHistory() {

            let tempYY = ""
            let tempMM = ""
            // 날짜 데이터 파싱
            if (this.startMM < 10) {
                tempMM = "0" + this.startMM
            } else {
                tempMM = this.startMM
            }
            let startDate = this.startYY.toString() + '-' + tempMM

            if (this.endMM < 10) {
                tempMM = "0" + this.endMM
            } else {
                tempMM = this.endMM
            }
            let endDate = this.endYY.toString() + '-' + tempMM

            let params = {
                type: this.type
                , purPayWay: this.purPayWay
                , idxCrmCompany: this.idxCrmCompany
                , pageSize: this.pageSize
                , pageNum: this.pageNum
                , startDate: startDate
                , endDate: endDate
            }

            this.loading = true;

            this.axios.get('/api/v1/client/history/pur', {
                params: params
            })
                .then(res => {
                    if (res.data.err === 0) {
                        this.purList = res.data.purList
                        this.purchase = res.data.purchase

                        if (res.data.purListSize) {
                            this.listLength = res.data.purListSize
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        this.purchase = ''
                        this.purList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                }).finally(()=>{
                    this.loading = false;
                });
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getPurchaseHistory()
            }
        },

        paging(index) {
            this.pageNum = index
            this.getPurchaseHistory()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getPurchaseHistory()
            }
        },

    }
}
</script>
